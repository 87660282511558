import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";
import {
  PURGE_AUTH,
  SET_AUTH,
  SET_ERROR,
  UPDATE_USER,
  VERIFY_AUTH,
} from "../constants";
import {
  CATEGORIES_GET_REQUEST,
  CATEGORIES_GET_SUCCESS,
  CATEGORIES_GET_FAIL,
  CATEGORIES_POST_REQUEST,
  CATEGORIES_POST_SUCCESS,
  CATEGORIES_POST_FAIL,
  CATEGORIES_PATCH_REQUEST,
  CATEGORIES_PATCH_SUCCESS,
  CATEGORIES_PATCH_FAIL,
  CATEGORIES_DELETE_REQUEST,
  CATEGORIES_DELETE_SUCCESS,
  CATEGORIES_DELETE_FAIL,
} from "../constants/setting.constants";

export default {
  async getCategories({ commit }) {
    try {
      commit(CATEGORIES_GET_REQUEST);
      const { data } = await ApiService.get(
        "items/categories?fields=id,arabic_title,english_title,arabic_description,english_description,background_color,sequence,parent_category.english_title,parent_category.id,category_image.id,component_type_id.title"
      );
      return commit(CATEGORIES_GET_SUCCESS, data.data);
    } catch (error) {
      console.log("error", error);
      commit(CATEGORIES_GET_FAIL, error);
      return;
    }
  },

  async postCategory({ commit }, credentials) {
    try {
      commit(CATEGORIES_POST_REQUEST);
      const { data } = await ApiService.post("items/categories", credentials);
      return commit(CATEGORIES_POST_SUCCESS, data.data);
    } catch (error) {
      console.log("error", error);
      commit(CATEGORIES_POST_FAIL, error);
      return;
    }
  },

  async patchCategory({ commit }, credentials) {
    try {
      commit(CATEGORIES_PATCH_REQUEST);
      console.log(credentials);
      const { data } = await ApiService.update(
        "items/categories",
        credentials.id,
        credentials
      );
      return commit(CATEGORIES_PATCH_SUCCESS, data.data);
    } catch (error) {
      console.log("error", error);
      commit(CATEGORIES_PATCH_FAIL, error);
      return;
    }
  },

  async deleteCategory({ commit }, credentials) {
    try {
      commit(CATEGORIES_DELETE_REQUEST);
      const { data } = await ApiService.delete(
        `items/categories/${credentials.id}`
      );
      return commit(CATEGORIES_DELETE_SUCCESS, data.data);
    } catch (error) {
      console.log("error", error);
      commit(CATEGORIES_DELETE_FAIL, error);
      return;
    }
  },
};
