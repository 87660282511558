import { localStoragePrefix } from "@/config";
import { PURGE_AUTH, SET_AUTH, SET_ERROR } from "../constants";
import JwtService from "@/services/jwt.service";
import {
  CATEGORIES_GET_REQUEST,
  CATEGORIES_GET_SUCCESS,
  CATEGORIES_GET_FAIL,
  CATEGORIES_POST_REQUEST,
  CATEGORIES_POST_SUCCESS,
  CATEGORIES_POST_FAIL,
  CATEGORIES_PATCH_REQUEST,
  CATEGORIES_PATCH_SUCCESS,
  CATEGORIES_PATCH_FAIL,
  CATEGORIES_DELETE_REQUEST,
  CATEGORIES_DELETE_SUCCESS,
  CATEGORIES_DELETE_FAIL,
} from "../constants/setting.constants";

export default {
  auth_request(state) {
    state.authStatus = "loading";
  },
  auth_success(state, { token, user }) {
    state.authStatus = "success";
    state.token = token;
    state.user = user;
  },
  auth_error(state) {
    state.authStatus = "error";
  },

  logout(state) {
    state.authStatus = "";
    state.token = "";
  },

  [CATEGORIES_GET_FAIL](state, error) {
    return (state.errorGetCategory = error);
  },

  [CATEGORIES_POST_FAIL](state, error) {
    return (state.errorPostCategory = error);
  },

  [CATEGORIES_PATCH_FAIL](state, error) {
    return (state.errorPatchCategory = error);
  },

  [CATEGORIES_DELETE_FAIL](state, error) {
    return (state.errorDeleteCategory = error);
  },

  [CATEGORIES_GET_REQUEST](state) {
    return (state.loadingGetCategory = true);
  },

  [CATEGORIES_POST_REQUEST](state) {
    return (state.loadingPostCategory = true);
  },

  [CATEGORIES_DELETE_REQUEST](state) {
    return (state.loadingDeleteCategory = true);
  },

  [CATEGORIES_PATCH_REQUEST](state) {
    return (state.loadingPatchCategory = true);
  },

  [CATEGORIES_GET_SUCCESS](state, CategoryData) {
    state.categories = CategoryData;
    state.errorGetCategory = {};
    state.loadingGetCategory = false;
  },

  [CATEGORIES_POST_SUCCESS](state, CategoryData) {
    state.addedCat = CategoryData;
    state.errorPostCategory = {};
    state.loadingPostCategory = false;
  },

  [CATEGORIES_PATCH_SUCCESS](state, CategoryData) {
    state.updatedCat = CategoryData;
    state.errorPatchCategory = {};
    state.loadingPatchCategory = false;
  },

  [CATEGORIES_DELETE_SUCCESS](state, CategoryData) {
    state.DeletedCat = CategoryData;
    state.errorDeletedCategory = {};
    state.loadingDeletedCategory = false;
  },
};
