// export default {
//   authorized: (state) => !!state.token,
//   authstatus: (state) => state.authStatus,
//   currentUser: (state) => state.user,
//   isAuth: (state) => state.isAuthentiLanguageed,
//   userGetter: (state) => {
//     return {
//       isAuth: state.isAuth,
//       loading: state.loading,
//       error: state.error,
//       // isAuth: state.isAuth,
//     };
//   },
// };

export default {
  // authorized: (state) => !!state.token,
  authstatus: (state) => state.authStatus,
  // currentUser: (state) => state.user,
  // isAuth: (state) => state.isAuthentiLanguageed,
  languageGetter: (state) => {
    return {
      language: state.language,
      addedLanguage: state.addedLanguage,
      updatedLanguage: state.updatedLanguage,
      deletedLanguage: state.deletedLanguage,
      errorGetLanguage: state.errorGetLanguage,
      errorPostLanguage: state.errorPostLanguage,
      errorPatchLanguage: state.errorPatchLanguage,
      errorDeleteLanguage: state.errorDeleteLanguage,
      loadingGetLanguage: state.loadingGetLanguage,
      loadingPostLanguage: state.loadingPostLanguage,
      loadingDeleteLanguage: state.loadingDeleteLanguage,
      loadingPatchLanguage: state.loadingPatchLanguage,
    };
  },
};
