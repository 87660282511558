import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";
import {
  PURGE_AUTH,
  SET_AUTH,
  SET_ERROR,
  UPDATE_USER,
  VERIFY_AUTH,
} from "../constants";
import {
  FILE_POST_REQUEST,
  FILE_POST_SUCCESS,
  FILE_POST_FAIL,
  FILES_GET_REQUEST,
  FILES_GET_SUCCESS,
  FILES_GET_FAIL,
  FILE_DELETE_REQUEST,
  FILE_DELETE_SUCCESS,
  FILE_DELETE_FAIL,
} from "../constants/file.constants";

export default {
  async postFile({ commit }, credentials) {
    try {
      commit(FILE_POST_REQUEST);
      const { data } = await ApiService.postFile("files", credentials, 0, null);
      return commit(FILE_POST_SUCCESS, data.data);
    } catch (error) {
      console.log("error", error);
      commit(FILE_POST_FAIL, error);
      return;
    }
  },

  async getFile({ commit }, credentials) {
    try {
      commit(FILES_GET_REQUEST);
      const { data } = await ApiService.getFile("files", credentials);
      return commit(FILES_GET_SUCCESS, data.data);
    } catch (error) {
      console.log("error", error);
      commit(FILES_GET_FAIL, error);
      return;
    }
  },

  async deleteFile({ commit }, credentials) {
    try {
      commit(FILE_DELETE_REQUEST);
      const { data } = await ApiService.delete(`files/${credentials}`);
      return commit(FILE_DELETE_SUCCESS, data.data);
    } catch (error) {
      console.log("error", error);
      commit(FILE_DELETE_FAIL, error);
      return;
    }
  },
};
