export const FILE_POST_REQUEST = "FILE_POST_REQUEST";
export const FILE_POST_SUCCESS = "FILE_POST_SUCCESS";
export const FILE_POST_FAIL = "FILE_POST_FAIL";

export const FILES_GET_REQUEST = "FILES_GET_REQUEST";
export const FILES_GET_SUCCESS = "FILES_GET_SUCCESS";
export const FILES_GET_FAIL = "FILES_GET_FAIL";

export const FILE_DELETE_REQUEST = "FILE_DELETE_REQUEST";
export const FILE_DELETE_SUCCESS = "FILE_DELETE_SUCCESS";
export const FILE_DELETE_FAIL = "FILE_DELETE_FAIL";
