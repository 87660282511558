import { localStoragePrefix } from "@/config";
import { TOKEN } from "../constants";

export default {
  categories: [],
  addedCat: {},
  updatedCat: {},
  deletedCat: {},
  errorGetCategory: null,
  errorPostCategory: null,
  errorPatchCategory: null,
  errorDeleteCategory: null,
  loadingGetCategory: false,
  loadingPostCategory: false,
  loadingDeleteCategory: false,
  loadingPatchCategory: false,
};
