//CATEGORIES
export const CATEGORIES_GET_REQUEST = "CATEGORIES_GET_REQUEST";
export const CATEGORIES_GET_SUCCESS = "CATEGORIES_GET_SUCCESS";
export const CATEGORIES_GET_FAIL = "CATEGORIES_GET_FAIL";

export const CATEGORIES_POST_REQUEST = "CATEGORIES_POST_REQUEST";
export const CATEGORIES_POST_SUCCESS = "CATEGORIES_POST_SUCCESS";
export const CATEGORIES_POST_FAIL = "CATEGORIES_POST_FAIL";

export const CATEGORIES_PATCH_REQUEST = "CATEGORIES_PATCH_REQUEST";
export const CATEGORIES_PATCH_SUCCESS = "CATEGORIES_PATCH_SUCCESS";
export const CATEGORIES_PATCH_FAIL = "CATEGORIES_PATCH_FAIL";

export const CATEGORIES_DELETE_REQUEST = "CATEGORIES_DELETE_REQUEST";
export const CATEGORIES_DELETE_SUCCESS = "CATEGORIES_DELETE_SUCCESS";
export const CATEGORIES_DELETE_FAIL = "CATEGORIES_DELETE_FAIL";

//TAGS
export const TAGS_GET_REQUEST = "TAGS_GET_REQUEST";
export const TAGS_GET_SUCCESS = "TAGS_GET_SUCCESS";
export const TAGS_GET_FAIL = "TAGS_GET_FAIL";

export const TAGS_POST_REQUEST = "TAGS_POST_REQUEST";
export const TAGS_POST_SUCCESS = "TAGS_POST_SUCCESS";
export const TAGS_POST_FAIL = "TAGS_POST_FAIL";

export const TAGS_PATCH_REQUEST = "TAGS_PATCH_REQUEST";
export const TAGS_PATCH_SUCCESS = "TAGS_PATCH_SUCCESS";
export const TAGS_PATCH_FAIL = "TAGS_PATCH_FAIL";

export const TAGS_DELETE_REQUEST = "TAGS_DELETE_REQUEST";
export const TAGS_DELETE_SUCCESS = "TAGS_DELETE_SUCCESS";
export const TAGS_DELETE_FAIL = "TAGS_DELETE_FAIL";

//ATTRIBUTES
export const ATTRIBUTES_GET_REQUEST = "ATTRIBUTES_GET_REQUEST";
export const ATTRIBUTES_GET_SUCCESS = "ATTRIBUTES_GET_SUCCESS";
export const ATTRIBUTES_GET_FAIL = "ATTRIBUTES_GET_FAIL";

export const ATTRIBUTES_POST_REQUEST = "ATTRIBUTES_POST_REQUEST";
export const ATTRIBUTES_POST_SUCCESS = "ATTRIBUTES_POST_SUCCESS";
export const ATTRIBUTES_POST_FAIL = "ATTRIBUTES_POST_FAIL";

export const ATTRIBUTES_PATCH_REQUEST = "ATTRIBUTES_PATCH_REQUEST";
export const ATTRIBUTES_PATCH_SUCCESS = "ATTRIBUTES_PATCH_SUCCESS";
export const ATTRIBUTES_PATCH_FAIL = "ATTRIBUTES_PATCH_FAIL";

export const ATTRIBUTES_DELETE_REQUEST = "ATTRIBUTES_DELETE_REQUEST";
export const ATTRIBUTES_DELETE_SUCCESS = "ATTRIBUTES_DELETE_SUCCESS";
export const ATTRIBUTES_DELETE_FAIL = "ATTRIBUTES_DELETE_FAIL";

//COUNTERIES
export const COUNTERIES_GET_REQUEST = "COUNTERIES_GET_REQUEST";
export const COUNTERIES_GET_SUCCESS = "COUNTERIES_GET_SUCCESS";
export const COUNTERIES_GET_FAIL = "COUNTERIES_GET_FAIL";

export const COUNTERIES_POST_REQUEST = "COUNTERIES_POST_REQUEST";
export const COUNTERIES_POST_SUCCESS = "COUNTERIES_POST_SUCCESS";
export const COUNTERIES_POST_FAIL = "COUNTERIES_POST_FAIL";

export const COUNTERIES_PATCH_REQUEST = "COUNTERIES_PATCH_REQUEST";
export const COUNTERIES_PATCH_SUCCESS = "COUNTERIES_PATCH_SUCCESS";
export const COUNTERIES_PATCH_FAIL = "COUNTERIES_PATCH_FAIL";

export const COUNTERIES_DELETE_REQUEST = "COUNTERIES_DELETE_REQUEST";
export const COUNTERIES_DELETE_SUCCESS = "COUNTERIES_DELETE_SUCCESS";
export const COUNTERIES_DELETE_FAIL = "COUNTERIES_DELETE_FAIL";

//BRANDS
export const BRANDS_GET_REQUEST = "BRANDS_GET_REQUEST";
export const BRANDS_GET_SUCCESS = "BRANDS_GET_SUCCESS";
export const BRANDS_GET_FAIL = "BRANDS_GET_FAIL";

export const BRANDS_POST_REQUEST = "BRANDS_POST_REQUEST";
export const BRANDS_POST_SUCCESS = "BRANDS_POST_SUCCESS";
export const BRANDS_POST_FAIL = "BRANDS_POST_FAIL";

export const BRANDS_PATCH_REQUEST = "BRANDS_PATCH_REQUEST";
export const BRANDS_PATCH_SUCCESS = "BRANDS_PATCH_SUCCESS";
export const BRANDS_PATCH_FAIL = "BRANDS_PATCH_FAIL";

export const BRANDS_DELETE_REQUEST = "BRANDS_DELETE_REQUEST";
export const BRANDS_DELETE_SUCCESS = "BRANDS_DELETE_SUCCESS";
export const BRANDS_DELETE_FAIL = "BRANDS_DELETE_FAIL";

//AUTHORS
export const AUTHORS_GET_REQUEST = "AUTHORS_GET_REQUEST";
export const AUTHORS_GET_SUCCESS = "AUTHORS_GET_SUCCESS";
export const AUTHORS_GET_FAIL = "AUTHORS_GET_FAIL";

export const AUTHORS_POST_REQUEST = "AUTHORS_POST_REQUEST";
export const AUTHORS_POST_SUCCESS = "AUTHORS_POST_SUCCESS";
export const AUTHORS_POST_FAIL = "AUTHORS_POST_FAIL";

export const AUTHORS_PATCH_REQUEST = "AUTHORS_PATCH_REQUEST";
export const AUTHORS_PATCH_SUCCESS = "AUTHORS_PATCH_SUCCESS";
export const AUTHORS_PATCH_FAIL = "AUTHORS_PATCH_FAIL";

export const AUTHORS_DELETE_REQUEST = "AUTHORS_DELETE_REQUEST";
export const AUTHORS_DELETE_SUCCESS = "AUTHORS_DELETE_SUCCESS";
export const AUTHORS_DELETE_FAIL = "AUTHORS_DELETE_FAIL";

//PUBLISHERS
export const PUBLISHERS_GET_REQUEST = "PUBLISHERS_GET_REQUEST";
export const PUBLISHERS_GET_SUCCESS = "PUBLISHERS_GET_SUCCESS";
export const PUBLISHERS_GET_FAIL = "PUBLISHERS_GET_FAIL";

export const PUBLISHERS_POST_REQUEST = "PUBLISHERS_POST_REQUEST";
export const PUBLISHERS_POST_SUCCESS = "PUBLISHERS_POST_SUCCESS";
export const PUBLISHERS_POST_FAIL = "PUBLISHERS_POST_FAIL";

export const PUBLISHERS_PATCH_REQUEST = "PUBLISHERS_PATCH_REQUEST";
export const PUBLISHERS_PATCH_SUCCESS = "PUBLISHERS_PATCH_SUCCESS";
export const PUBLISHERS_PATCH_FAIL = "PUBLISHERS_PATCH_FAIL";

export const PUBLISHERS_DELETE_REQUEST = "PUBLISHERS_DELETE_REQUEST";
export const PUBLISHERS_DELETE_SUCCESS = "PUBLISHERS_DELETE_SUCCESS";
export const PUBLISHERS_DELETE_FAIL = "PUBLISHERS_DELETE_FAIL";

//LANGUAGE
export const LANGUAGE_GET_REQUEST = "LANGUAGE_GET_REQUEST";
export const LANGUAGE_GET_SUCCESS = "LANGUAGE_GET_SUCCESS";
export const LANGUAGE_GET_FAIL = "LANGUAGE_GET_FAIL";

export const LANGUAGE_POST_REQUEST = "LANGUAGE_POST_REQUEST";
export const LANGUAGE_POST_SUCCESS = "LANGUAGE_POST_SUCCESS";
export const LANGUAGE_POST_FAIL = "LANGUAGE_POST_FAIL";

export const LANGUAGE_PATCH_REQUEST = "LANGUAGE_PATCH_REQUEST";
export const LANGUAGE_PATCH_SUCCESS = "LANGUAGE_PATCH_SUCCESS";
export const LANGUAGE_PATCH_FAIL = "LANGUAGE_PATCH_FAIL";

export const LANGUAGE_DELETE_REQUEST = "LANGUAGE_DELETE_REQUEST";
export const LANGUAGE_DELETE_SUCCESS = "LANGUAGE_DELETE_SUCCESS";
export const LANGUAGE_DELETE_FAIL = "LANGUAGE_DELETE_FAIL";

//CURRENCIES
export const CURRENCIES_GET_REQUEST = "CURRENCIES_GET_REQUEST";
export const CURRENCIES_GET_SUCCESS = "CURRENCIES_GET_SUCCESS";
export const CURRENCIES_GET_FAIL = "CURRENCIES_GET_FAIL";

export const CURRENCIES_POST_REQUEST = "CURRENCIES_POST_REQUEST";
export const CURRENCIES_POST_SUCCESS = "CURRENCIES_POST_SUCCESS";
export const CURRENCIES_POST_FAIL = "CURRENCIES_POST_FAIL";

export const CURRENCIES_PATCH_REQUEST = "CURRENCIES_PATCH_REQUEST";
export const CURRENCIES_PATCH_SUCCESS = "CURRENCIES_PATCH_SUCCESS";
export const CURRENCIES_PATCH_FAIL = "CURRENCIES_PATCH_FAIL";

export const CURRENCIES_DELETE_REQUEST = "CURRENCIES_DELETE_REQUEST";
export const CURRENCIES_DELETE_SUCCESS = "CURRENCIES_DELETE_SUCCESS";
export const CURRENCIES_DELETE_FAIL = "CURRENCIES_DELETE_FAIL";
