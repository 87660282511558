// export default {
//   authorized: (state) => !!state.token,
//   authstatus: (state) => state.authStatus,
//   currentUser: (state) => state.user,
//   isAuth: (state) => state.isAuthenticated,
//   userGetter: (state) => {
//     return {
//       isAuth: state.isAuth,
//       loading: state.loading,
//       error: state.error,
//       // isAuth: state.isAuth,
//     };
//   },
// };

export default {
  // authorized: (state) => !!state.token,
  authstatus: (state) => state.authStatus,
  // currentUser: (state) => state.user,
  // isAuth: (state) => state.isAuthenticated,
  categoryGetter: (state) => {
    return {
      categories: state.categories,
      addedCat: state.addedCat,
      updatedCat: state.updatedCat,
      deletedCat: state.deletedCat,
      errorGetCategory: state.errorGetCategory,
      errorPostCategory: state.errorPostCategory,
      errorPatchCategory: state.errorPatchCategory,
      errorDeleteCategory: state.errorDeleteCategory,
      loadingGetCategory: state.loadingGetCategory,
      loadingPostCategory: state.loadingPostCategory,
      loadingDeleteCategory: state.loadingDeleteCategory,
      loadingPatchCategory: state.loadingPatchCategory,
    };
  },
};
