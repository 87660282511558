import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";
import {
  PURGE_AUTH,
  SET_AUTH,
  SET_ERROR,
  UPDATE_USER,
  VERIFY_AUTH,
} from "../constants";
import {
  CURRENCIES_GET_REQUEST,
  CURRENCIES_GET_SUCCESS,
  CURRENCIES_GET_FAIL,
} from "../constants/setting.constants";

export default {
  async getCurrency({ commit }) {
    try {
      commit(CURRENCIES_GET_REQUEST);
      const { data } = await ApiService.get(
        "items/currency?fields=id,arabic_title,english_title,title,image"
      );
      return commit(CURRENCIES_GET_SUCCESS, data.data);
    } catch (error) {
      console.log("error", error);
      commit(CURRENCIES_GET_FAIL, error);
      return;
    }
  },
};
