export default {
  // authorized: (state) => !!state.token,
  authstatus: (state) => state.authStatus,
  // currentUser: (state) => state.user,
  // isAuth: (state) => state.isAuthentiFileed,
  fileGetter: (state) => {
    return {
      addedFile: state.addedFile,
      file: state.file,
      errorPostFile: state.errorPostFile,
      loadingPostFile: state.loadingPostFile,
      errorGetFile: state.errorGetFile,
      loadingGetFile: state.loadingGetFile,
      deletedFile: state.deletedFile,
      errorDeleteFile: state.errorDeleteFile,
      loadingDeleteFile: state.loadingDeleteFile,
    };
  },
};
