import { localStoragePrefix } from "@/config";
import { PURGE_AUTH, SET_AUTH, SET_ERROR } from "../constants";
import JwtService from "@/services/jwt.service";
import {
  LANGUAGE_GET_REQUEST,
  LANGUAGE_GET_SUCCESS,
  LANGUAGE_GET_FAIL,
  LANGUAGE_POST_REQUEST,
  LANGUAGE_POST_SUCCESS,
  LANGUAGE_POST_FAIL,
  LANGUAGE_PATCH_REQUEST,
  LANGUAGE_PATCH_SUCCESS,
  LANGUAGE_PATCH_FAIL,
  LANGUAGE_DELETE_REQUEST,
  LANGUAGE_DELETE_SUCCESS,
  LANGUAGE_DELETE_FAIL,
} from "../constants/setting.constants";

export default {
  auth_request(state) {
    state.authStatus = "loading";
  },
  auth_success(state, { token, user }) {
    state.authStatus = "success";
    state.token = token;
    state.user = user;
  },
  auth_error(state) {
    state.authStatus = "error";
  },

  logout(state) {
    state.authStatus = "";
    state.token = "";
  },

  [LANGUAGE_GET_FAIL](state, error) {
    return (state.errorGetLanguage = error);
  },

  [LANGUAGE_POST_FAIL](state, error) {
    return (state.errorPostLanguage = error);
  },

  [LANGUAGE_PATCH_FAIL](state, error) {
    return (state.errorPatchLanguage = error);
  },

  [LANGUAGE_DELETE_FAIL](state, error) {
    return (state.errorDeleteLanguage = error);
  },

  [LANGUAGE_GET_REQUEST](state) {
    return (state.loadingGetLanguage = true);
  },

  [LANGUAGE_POST_REQUEST](state) {
    return (state.loadingPostLanguage = true);
  },

  [LANGUAGE_DELETE_REQUEST](state) {
    return (state.loadingDeleteLanguage = true);
  },

  [LANGUAGE_PATCH_REQUEST](state) {
    return (state.loadingPatchLanguage = true);
  },

  [LANGUAGE_GET_SUCCESS](state, LanguageData) {
    state.language = LanguageData;
    state.errorGetLanguage = {};
    state.loadingGetLanguage = false;
  },

  [LANGUAGE_POST_SUCCESS](state, LanguageData) {
    state.addedLanguage = LanguageData;
    state.errorPostLanguage = {};
    state.loadingPostLanguage = false;
  },

  [LANGUAGE_PATCH_SUCCESS](state, LanguageData) {
    state.updatedLanguage = LanguageData;
    state.errorPatchLanguage = {};
    state.loadingPatchLanguage = false;
  },

  [LANGUAGE_DELETE_SUCCESS](state, LanguageData) {
    state.DeletedLanguage = LanguageData;
    state.errorDeletedLanguage = {};
    state.loadingDeletedLanguage = false;
  },
};
