import { localStoragePrefix } from "@/config";
import { PURGE_AUTH, SET_AUTH, SET_ERROR } from "../constants";
import JwtService from "@/services/jwt.service";
import {
  CURRENCIES_GET_REQUEST,
  CURRENCIES_GET_SUCCESS,
  CURRENCIES_GET_FAIL,
} from "../constants/setting.constants";

export default {
  auth_request(state) {
    state.authStatus = "loading";
  },
  auth_success(state, { token, user }) {
    state.authStatus = "success";
    state.token = token;
    state.user = user;
  },
  auth_error(state) {
    state.authStatus = "error";
  },

  logout(state) {
    state.authStatus = "";
    state.token = "";
  },

  [CURRENCIES_GET_FAIL](state, error) {
    return (state.errorGetCurrency = error);
  },

  [CURRENCIES_GET_REQUEST](state) {
    return (state.loadingGetCurrency = true);
  },

  [CURRENCIES_GET_SUCCESS](state, CurrencyData) {
    state.currency = CurrencyData;
    state.errorGetCurrency = {};
    state.loadingGetCurrency = false;
  },
};
