// Lib imports
import Vue from "vue";
import Vuex from "vuex";

// Store functionality
import actions from "./actions";
import getters from "./getters";
// import modules from './modules';
import mutations from "./mutations";
import state from "./state";
import userModule from "./user.module";
import categoryModule from "./category.module";
import fileModule from "./file.module";
import languageModule from "./language.module";
import currencyModule from "./currency.module";

Vue.use(Vuex);

// Create a new store
const store = new Vuex.Store({
  actions,
  getters,
  // modules,
  userModule,
  categoryModule,
  fileModule,
  languageModule,
  currencyModule,
  mutations,
  state,
  modules: {
    userModule,
    categoryModule,
    fileModule,
    languageModule,
    currencyModule,
  },
});

export default store;
