import { localStoragePrefix } from "@/config";
import { PURGE_AUTH, SET_AUTH, SET_ERROR } from "../constants";
import JwtService from "@/services/jwt.service";
import {
  FILE_POST_REQUEST,
  FILE_POST_SUCCESS,
  FILE_POST_FAIL,
  FILES_GET_REQUEST,
  FILES_GET_SUCCESS,
  FILES_GET_FAIL,
  FILE_DELETE_REQUEST,
  FILE_DELETE_SUCCESS,
  FILE_DELETE_FAIL,
} from "../constants/file.constants";

export default {
  [FILES_GET_FAIL](state, error) {
    return (state.errorGetFile = error);
  },

  [FILE_POST_FAIL](state, error) {
    return (state.errorPostFile = error);
  },

  [FILE_DELETE_FAIL](state, error) {
    return (state.errorDeleteFile = error);
  },

  [FILES_GET_REQUEST](state) {
    return (state.loadingGetFile = true);
  },

  [FILE_POST_REQUEST](state) {
    return (state.loadingPostFile = true);
  },

  [FILE_DELETE_REQUEST](state) {
    return (state.loadingDeleteFile = true);
  },

  [FILES_GET_SUCCESS](state, FileData) {
    state.file = FileData;
    state.errorGetFile = {};
    state.loadingGetFile = false;
  },

  [FILE_POST_SUCCESS](state, FileData) {
    state.addedFile = FileData.id;
    console.log(FileData);
    console.log(state.addedFile);

    state.errorPostFile = {};
    state.loadingPostFile = false;
  },

  [FILE_DELETE_SUCCESS](state, FileData) {
    state.DeletedFile = FileData;
    state.errorDeletedFile = {};
    state.loadingDeletedFile = false;
  },
};
