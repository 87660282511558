import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";
import {
  PURGE_AUTH,
  SET_AUTH,
  SET_ERROR,
  UPDATE_USER,
  VERIFY_AUTH,
} from "../constants";
import {
  LANGUAGE_GET_REQUEST,
  LANGUAGE_GET_SUCCESS,
  LANGUAGE_GET_FAIL,
  LANGUAGE_POST_REQUEST,
  LANGUAGE_POST_SUCCESS,
  LANGUAGE_POST_FAIL,
  LANGUAGE_PATCH_REQUEST,
  LANGUAGE_PATCH_SUCCESS,
  LANGUAGE_PATCH_FAIL,
  LANGUAGE_DELETE_REQUEST,
  LANGUAGE_DELETE_SUCCESS,
  LANGUAGE_DELETE_FAIL,
} from "../constants/setting.constants";

export default {
  async getLanguage({ commit }) {
    try {
      commit(LANGUAGE_GET_REQUEST);
      const { data } = await ApiService.get(
        "items/languages?fields=id,arabic_title,english_title,title"
      );
      return commit(LANGUAGE_GET_SUCCESS, data.data);
    } catch (error) {
      console.log("error", error);
      commit(LANGUAGE_GET_FAIL, error);
      return;
    }
  },

  async postLanguage({ commit }, credentials) {
    try {
      commit(LANGUAGE_POST_REQUEST);
      const { data } = await ApiService.post("items/languages", credentials);
      return commit(LANGUAGE_POST_SUCCESS, data.data);
    } catch (error) {
      console.log("error", error);
      commit(LANGUAGE_POST_FAIL, error);
      return;
    }
  },

  async patchLanguage({ commit }, credentials) {
    try {
      commit(LANGUAGE_PATCH_REQUEST);
      const { data } = await ApiService.update(
        "items/languages",
        credentials.id,
        credentials
      );
      return commit(LANGUAGE_PATCH_SUCCESS, data.data);
    } catch (error) {
      console.log("error", error);
      commit(LANGUAGE_PATCH_FAIL, error);
      return;
    }
  },

  async deleteLanguage({ commit }, credentials) {
    try {
      commit(LANGUAGE_DELETE_REQUEST);
      const { data } = await ApiService.delete(
        `items/languages/${credentials.id}`
      );
      return commit(LANGUAGE_DELETE_SUCCESS, data.data);
    } catch (error) {
      console.log("error", error);
      commit(LANGUAGE_DELETE_FAIL, error);
      return;
    }
  },
};
