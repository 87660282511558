import { localStoragePrefix } from "@/config";
import { TOKEN } from "../constants";
export default {
  addedFile: null,
  errorPostFile: null,
  loadingPostFile: false,
  errorGetFile: null,
  loadingGetFile: false,
  file: {},
  errorDeleteFile: null,
  loadingDeleteFile: false,
  deletedFile: {},
};
