import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "../services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL =
      "https://diwanworld.app/diwanapi/api/public/diwan";
    this.setHeader();
  },

  /**
   * Set the default HTTP request headers
   * 'Content-Type': 'multipart/form-data'
   * contentType="multipart/form-data"
   */
  getUserInfo() {
    return JwtService.getUserInfo();
  },

  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  // change
  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "", token = false) {
    if (slug == "") {
      if (token) {
        return Vue.axios
          .get(`${resource}`, {
            headers: {
              Authorization: `Bearer MvWsGYxB6uqj5k5xZMndwhJJaGyGRUaV`,
            },
          })
          .catch((error) => {
            throw new Error(`[KT] ApiService ${error}`);
          });
      } else {
        return Vue.axios.get(`${resource}`).catch((error) => {
          throw new Error(`[KT] ApiService ${error}`);
        });
      }
    } else {
      if (token) {
        return Vue.axios
          .get(`${resource}/${slug}`, {
            headers: {
              Authorization: `Bearer MvWsGYxB6uqj5k5xZMndwhJJaGyGRUaV`,
            },
          })
          .catch((error) => {
            throw new Error(`[KT] ApiService ${error}`);
          });
      } else {
        return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
          throw new Error(`[KT] ApiService ${error}`);
        });
      }
    }
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  registerUser(resource, params) {
    try {
      return Vue.axios.post(`${resource}`, params, {
        headers: {
          Authorization: `Bearer MvWsGYxB6uqj5k5xZMndwhJJaGyGRUaV`,
        },
      });
    } catch (error) {
      return error;
    }
  },

  postWithToken(resource, payload) {
    try {
      console.log("req start");
      return Vue.axios.post(`${resource}`, payload, {
        headers: {
          Authorization: `Bearer MvWsGYxB6uqj5k5xZMndwhJJaGyGRUaV`,
        },
      });
    } catch (error) {
      return error;
    }
  },

  getDepartments(resource, slug) {
    try {
      return Vue.axios.get(`${resource}/${slug}`, {
        headers: {
          Authorization: `Bearer MvWsGYxB6uqj5k5xZMndwhJJaGyGRUaV`,
        },
      });
    } catch (error) {
      return error;
    }
  },

  postMdFile(resource, params, headers) {
    return Vue.axios.post(`${resource}`, params, { headers: headers });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  postFile(resource, params, index, uploadProgressCallBack) {
    return Vue.axios.post(`${resource}`, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function(progressEvent) {
        var p = parseInt(
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
        );
        if (uploadProgressCallBack) uploadProgressCallBack(index, p);
        return p;
      },
    });
  },

  getFile(resource, slug) {
    return Vue.axios.get(`${resource}/${slug}`);
  },
  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.patch(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
