import { localStoragePrefix } from "@/config";
import { TOKEN } from "../constants";

export default {
  Language: [],
  addedLanguage: {},
  updatedLanguage: {},
  deletedLanguage: {},
  errorGetLanguage: null,
  errorPostLanguage: null,
  errorPatchLanguage: null,
  errorDeleteLanguage: null,
  loadingGetLanguage: false,
  loadingPostLanguage: false,
  loadingDeleteLanguage: false,
  loadingPatchLanguage: false,
};
