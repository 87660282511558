export const PURGE_AUTH = "PURGE_AUTH";
export const SET_AUTH = "SET_AUTH";
export const SET_ERROR = "SET_ERROR";
export const VERIFY_AUTH = "VERIFY_AUTH";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const UPDATE_USER = "UPDATE_USER";
export const TOKEN = "TOKEN";
export const USERINFO = "userInfo";
