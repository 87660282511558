import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import("@/views/Index"),
      children: [
        // Dashboard
        {
          name: "Dashboard",
          path: "",
          component: () => import("@/views/Dashboard"),
        },
        // Pages
        {
          name: "RTL",
          path: "rtl",
          component: () => import("@/views/pages/Rtl"),
        },
        {
          name: "Timeline",
          path: "timeline",
          component: () => import("@/views/pages/Timeline"),
        },
        {
          name: "Invoice",
          path: "invoice",
          component: () => import("@/views/pages/Settings/Invoice"),
        },
        {
          name: "Invoice Product",
          path: "/invoiceproduct/:id",
          component: () =>
            import("@/views/pages/Settings/Invoice/InvoiceProducts"),
        },
        {
          name: "Countries",
          path: "countries",
          component: () => import("@/views/pages/Settings/Countries"),
        },
        {
          name: "Brands",
          path: "brands",
          component: () => import("@/views/pages/Settings/Brands"),
        },
        {
          name: "Categories",
          path: "categories",
          component: () => import("@/views/pages/Settings/Categories"),
        },
        {
          name: "Categories",
          path: "main-categories",
          component: () => import("@/views/pages/Settings/CategoriesMain"),
        },
        {
          name: "Library Categories",
          path: "library-categories",
          component: () => import("@/views/pages/Settings/CategoriesLib"),
        },
        {
          name: "Products",
          path: "publishers",
          component: () => import("@/views/pages/Settings/Publishers"),
        },
        {
          name: "Book Types",
          path: "book-types",
          component: () => import("@/views/pages/Settings/BookType"),
        },
        {
          name: "Tags",
          path: "tags",
          component: () => import("@/views/pages/Settings/Tags"),
        },
        {
          name: "Attributes",
          path: "attributes",
          component: () => import("@/views/pages/Settings/Attributes"),
        },
        {
          name: "Attribute Value",
          path: "attribute-value",
          component: () => import("@/views/pages/Settings/AttributeValue"),
        },
        {
          name: "Products",
          path: "authors",
          component: () => import("@/views/pages/Settings/Authors"),
        },
        {
          name: "Reviews",
          path: "reviews",
          component: () => import("@/views/pages/Settings/Reviews"),
        },
        {
          name: "Notify Me",
          path: "notify",
          component: () => import("@/views/pages/Settings/NotifyMe"),
        },
        {
          name: "Support",
          path: "support",
          component: () => import("@/views/pages/Settings/Support"),
        },
        {
          name: "Order Status",
          path: "order-status",
          component: () => import("@/views/pages/Settings/OrderStatus"),
        },
        {
          name: "Celebrity",
          path: "celebrity",
          component: () => import("@/views/pages/Settings/Celebrity"),
        },
        {
          name: "Celebrity Favourity",
          path: "celebrity-favourity",
          component: () => import("@/views/pages/Celebrities/CelebrityFav"),
        },
        {
          name: "Celebrity Product",
          path: "celebrity-product",
          component: () => import("@/views/pages/Celebrities/CelebrityPro"),
        },
        {
          name: "Languages",
          path: "languages",
          component: () => import("@/views/pages/Settings/Languages"),
        },
        {
          name: "Currencies",
          path: "currencies",
          component: () => import("@/views/pages/Settings/currencies"),
        },
        {
          name: "Banners",
          path: "banners",
          component: () => import("@/views/pages/Settings/Banners"),
        },
        {
          name: "Products",
          path: "product",
          component: () => import("@/views/pages/ProductPage/index"),
        },

        {
          name: "Coupons",
          path: "coupons",
          component: () => import("@/views/pages/Coupons/index"),
        },
        {
          name: "Package",
          path: "package",
          component: () => import("@/views/pages/Package/index"),
        },
        {
          name: "User Profile",
          path: "pages/user",
          component: () => import("@/views/pages/Profiles/UserProfile"),
        },
        {
          name: "Add User",
          path: "/add",
          component: () => import("@/views/pages/Profiles/Add"),
        },
        {
          name: "Notifications",
          path: "components/notifications",
          component: () => import("@/views/component/Notifications"),
        },
      ],
    },
  ],
});
