export default {
  // authorized: (state) => !!state.token,
  authstatus: (state) => state.authStatus,
  // currentUser: (state) => state.user,
  // isAuth: (state) => state.isAuthentiLanguageed,
  currencyGetter: (state) => {
    return {
      currency: state.currency,
      errorGetLanguage: state.errorGetLanguage,
      loadingGetLanguage: state.loadingGetLanguage,
    };
  },
};
