// const ID_TOKEN_KEY = 'id_token';

import { localStoragePrefix } from "../config";
import { TOKEN, USERINFO } from "../store/constants";

export const getToken = () => {
  // return 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MiwiZXhwIjoxMjE2MTI3MDM3NjEsInR0bCI6MjAwMDAwMDAwMCwidHlwZSI6ImF1dGgiLCJrZXkiOiJiOTdlNTMxYy02YWQyLTQ1ZTctYTk3OS1mYjgzYjc4Nzk4OTUiLCJwcm9qZWN0IjoiNHRhbGVudHMifQ.267zvp9_v00p9wj4u-YAgjWKLyg8rOPWcqMpz4z-r3Q';
  return window.localStorage.getItem(localStoragePrefix + TOKEN);
};

export const getUserInfo = () => {
  return window.localStorage.getItem(localStoragePrefix + USERINFO);
};

export const saveToken = (token) => {
  window.localStorage.setItem(localStoragePrefix + TOKEN, token);
};

export const destroyToken = () => {
  localStorage.clear();
};

export default { getToken, saveToken, destroyToken, getUserInfo };
